@import "../../../../ba_gp_storefront_core/cartridge/scss/default/mixin/breakpoint";
@import "components/variables";
@import "components/mixins";

$ex: ex-static-3-up;

.#{$ex} {
    &__inner {
        color: $black;
        margin: 0 auto;
        max-width: 1640px;
        padding: 24px 0 24px 20px;

        @include bp(min-width, $bp-medium) {
            padding-left: 16px;
            padding-right: 16px;
        }

        @include bp(min-width, $bp-large) {
            padding: 48px 20px;
        }
    }

    &__row {
        @include bp(min-width, $bp-medium) {
            display: flex;
            margin: 0 -10px;
        }
    }

    &__column {
        position: relative;

        @include bp(min-width, $bp-medium) {
            flex: 1;
            padding: 0 10px;
        }
    }

    &__image-wrapper {
        position: relative;
    }

    &__link-image {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
    }

    &__text {
        margin-top: 20px;
        text-align: center;

        @include bp(max-width, $bp-medium) {
            margin-top: 24px;
        }
    }

    &__headline {
        margin-bottom: 12px;
    }

    &__body-copy {
        margin-bottom: 12px;

        @include bp(min-width, $bp-large) {
            margin-bottom: 24px;
        }
    }

    img {
        width: 100%;
    }

    .owl-stage {
        display: flex;
        margin-left: -60px;

        @include bp(min-width, $bp-medium) {
            margin-left: 0;
        }
    }
}

.s3u-cta-only {
    .#{$ex} {
        &__headline,
        &__body-copy {
            display: none;
        }
    }
}

.s3u-overlay {
    .#{$ex} {
        &__inner {
            h2,
            p {
                color: $white;
            }
        }

        &__text {
            background: $clr_dark_mid;
            bottom: 0;
            left: 0;
            margin-top: 0;
            padding: 10px;
            position: absolute;
            right: 0;
            z-index: 1;

            @include bp(min-width, $bp-medium) {
                left: 10px;
                right: 10px;
            }

            @include bp(min-width, $bp-large) {
                padding: 12px;
            }
        }

        &__headline,
        &__body-copy {
            margin-bottom: 0;
        }

        &__cta {
            display: none;
        }
    }
}

// Override global styles
.page-designer-reference {
    .#{$ex} {
        &__headline,
        &__body-copy {
            > * {
                margin: 0;
            }
        }

        &__cta {
            color: inherit;

            &:active,
            &:focus,
            &:hover {
                color: inherit;
            }
        }
    }
}

.primary-content {
    .page-designer-reference {
        .#{$ex} {
            h2 {
                text-transform: none;
            }
        }
    }
}
